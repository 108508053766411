import { template as template_b9510247afa94972bdbd3936c6249341 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b9510247afa94972bdbd3936c6249341(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
