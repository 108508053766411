import { template as template_2f51cbed155e4ac780d3171bd071dbbd } from "@ember/template-compiler";
const WelcomeHeader = template_2f51cbed155e4ac780d3171bd071dbbd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
