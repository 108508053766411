import { template as template_2b819cf06ac5412996f23d4e497f37f8 } from "@ember/template-compiler";
const FKText = template_2b819cf06ac5412996f23d4e497f37f8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
