import { template as template_ad6646e6d76b4628a528190bf832b2f1 } from "@ember/template-compiler";
const FKLabel = template_ad6646e6d76b4628a528190bf832b2f1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
